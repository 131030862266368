<template>
  <v-list-item
    v-if="all || isAfter()"
  >
    <v-list-item-content
    >
      <ShowFullText
        :text="title"
        ref="showfulltext"
      />
      <v-list-item-title 
        v-text="title"
        :class="textColor + '--text'"
        @click="openFullText"
      />
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import ShowFullText from '@/dialogs/ShowFullText.vue'
export default {
  components: {
    ShowFullText,
  },
  props: {
    item: Object,
    all: Boolean,
  },
  data: () => ({
  }),
  methods: {
    isAfter(){
      const now = new Date();
      const currentTime = now.getHours() * 100 + now.getMinutes()
      let dpTime = this.departureTime.toString()
      console.log("dpTime",dpTime.toString())
      if (dpTime.substring(0,2) == "00"){
        dpTime = "24".concat(dpTime.substring(2,4))
        console.log("dpTime",dpTime.toString())
      }
      if (dpTime >= currentTime){
        return true
      } else {
        return false
      }
    },
    openFullText(){
      this.$refs.showfulltext.open()
    },
  },
  computed: {
    textColor(){
//      const array = this.item.value.positionalValues
//      const length = array.length
//      const lastitem = array[length -1]
      const value = this.item.value
      if (value.isInAsSubStr("急行")){
        return "red"
      } else if (value.isInAsSubStr("準急")) {
        return "green"
      } else if (value.isInAsSubStr("快速")) {
        return "blue"
      } else if (value.isInAsSubStr("特急")) {
        return "purple"
      } else {
        return "black"
      }
    },
    title(){
      if (this.item.title){
        return this.item.title
      } else {
        const positional = this.item.value.positionalValues
        return positional[0] + "時" + positional[1] + "分発 " + positional.slice(2).join(" ")
      }
    },
    departureTime(){
      const positional = this.item.value.positionalValues
      if (positional[0].length > 2){
        return positional[0]
      } else {
        if (positional[0] == 0){
          return "00".concat(positional[1])
        } else {
          return positional[0] + positional[1]
        }
      }
    }
  }
}
</script>
