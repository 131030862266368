import { EncLst } from 'enclst-core-js'
export function readHome() {
    let data = localStorage.homeList
    console.log("data", data)
    let enclst = new EncLst(data)
    if (data == undefined || data == null || data ==""){
      enclst.title = "home"
      enclst.pushItem("https://raw.githubusercontent.com/UedaTakeyuki/EncLst/refs/heads/main/examples/EncycloList/lang/lang.enclst | lang")
      enclst.pushItem("https://raw.githubusercontent.com/UedaTakeyuki/EncLst/refs/heads/main/examples/TimeTables/menu.enclst | time table")
    }
    console.log("enclst", enclst)
    return enclst
  }