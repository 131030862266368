<template>
  <v-dialog
    v-model="dialog"
    persistent
    eager
  >
    <v-card>
      <v-card-text>
        <!-- text -->
        <v-textarea
          :value="text"
          auto-grow
          readonly
        />
      </v-card-text>
      <v-card-actions>
        <v-btn 
          text
          @click="close"
        >
          close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ""
    },
  },
  data () {
    return {
      dialog: false,
    }
  },
  methods: {
    /**
     * Open this dialog
     * @param object item An item of the list.
     */
    open(){
      /** open dialog */
      this.dialog = true
    },
    close(){
      this.dialog = false
    },
  }
}
</script>